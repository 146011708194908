import React, { useEffect, useState } from 'react';
import CommonModal from '../Home/modal.js';
import { useFormik } from 'formik';
import marriagePhoto2 from '../../assets/images/MettaMateImages/MIG1.avif';
import { Link } from 'react-router-dom';
import '../Home/css/register.css';
import apiUrl from '../server';
import axios from 'axios';
import waveIcon from '../../assets/images/MettaMateImages/Group189.svg';
import ourLogo from '../../assets/images/MettaMateImages/sblogo2.svg';
import googleIcon from '../../assets/images/MettaMateImages/Google.svg';
import linkedinIcon from '../../assets/images/MettaMateImages/linkedinIcon.svg';
import loginBanner from '../../assets/images/MettaMateImages/loginbanner2.gif';
import {
  Container,
  Col,
  Input,
  Label,
  Button,
  Form,
  FormFeedback,
  Card,
  CardTitle,
  CardText,
  CardBody,
} from 'reactstrap';
import { Row } from 'reactstrap';
import { useNavigate } from 'react-router-dom';
import FeatherIcon from 'feather-icons-react';

import loginBackgroundBanner from '../../assets/images/mainBG-image.jpg';

const UserLoginPageFunction = () => {
  const navigate = useNavigate();
  const [error, setError] = useState();
  const [success, setSuccess] = useState();
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [isLoading, setisLoading] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      username: '',
      password: '',
    },

    onSubmit: async (values) => {
      setisLoading(true);
      let password = document.getElementById('password');
      values.password = password.value;
      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(values),
      };
      try {
        fetch(apiUrl + 'auth/login', requestOptions)
          .then((response) => response.json())
          .then(async (data) => {
            if (data.message == 'OK') {
              localStorage.setItem('token', data.token);
              localStorage.setItem('memberID', data.memberID);
              await fetchconfigureDetails();
            } else if (data.message == 'Account Is Deactivated') {
              setError({
                title: '',
                message: 'Your Account has been Deactivated.',
              });
              localStorage.setItem('token', data.token);
              localStorage.setItem('memberID', data.memberID);
              await fetchconfigureDetails();
            } else if (data.message == 'Account Is Not Available') {
              setError({
                title: 'Login Failed!',
                message:
                  'Your Account has been Deleted. Please Contact System Admin.',
              });
            } else if (data.message == 'Login Id Not available') {
              setError({
                title: 'Login Failed!',
                message: data.message,
              });
            } else {
              setError({
                title: 'Login Failed!',
                message:
                  'Your username or password is incorrect. Please Try Again.',
              });
            }
            setisLoading(false);
          });
      } catch (error) {
        setisLoading(false);
        setError({
          title: 'Error Occured!',
          message: 'Something Went Wrong.',
        });
      }
    },
  });

  const fetchconfigureDetails = async () => {
    try {
      const response = await axios.get(
        apiUrl + 'members/short/' + localStorage.getItem('memberID'),
        {
          headers: {
            Authorization: 'Bearer' + ' ' + localStorage.getItem('token'),
            'Content-Type': 'application/json',
          },
        },
      );
      localStorage.setItem('configurationData', JSON.stringify(response.data));
      let fileurl = response.data.photopath1
        ? await getPhotoUrlDetails(response.data.photopath1)
        : undefined;
      localStorage.setItem('profilePicture', fileurl);
      let configurationData = JSON.parse(
        localStorage.getItem('configurationData'),
      );
      if (configurationData.membertypeid == 4) {
        navigate('/communitymember-home-page');
      } else {
        navigate('/allainceseeker-home-page');
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };
  const getPhotoUrlDetails = async (fileName) => {
    try {
      const response = await axios.get(
        apiUrl + 'members/filepath/' + fileName,
        {
          headers: {
            Authorization: 'Bearer' + ' ' + localStorage.getItem('token'),
            'Content-Type': 'application/json',
          },
        },
      );
      return response.data.filepath;
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };
  const scrollNavigation = () => {
    var doc = document.documentElement;
    var top = (window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0);
    if (top > 80) {
      document.querySelector('.shoppingbtn')?.classList.remove('btn-light');
      document.querySelector('.settingbtn')?.classList.remove('btn-light');
      document.querySelector('.shoppingbtn')?.classList.add('btn-primary');
      document.querySelector('.settingbtn')?.classList.add('btn-soft-primary');

      document.getElementById('topnav')?.classList.add('nav-sticky');
    } else {
      document.querySelector('.shoppingbtn')?.classList.remove('btn-primary');
      document
        .querySelector('.settingbtn')
        ?.classList.remove('btn-soft-primary');
      document.querySelector('.shoppingbtn')?.classList.add('btn-light');
      document.querySelector('.settingbtn')?.classList.add('btn-light');
      document.getElementById('topnav')?.classList.remove('nav-sticky');
    }
  };
  const [posts, setPosts] = useState([]);

  useEffect(() => {
    window.scrollTo(0, 0);
    document.body.classList = '';
    document.querySelectorAll('#buyButton').forEach((navLink) => {
      navLink.classList.add('btn-light');
      navLink.classList.remove('btn-soft-primary');
      document.getElementById('top-menu')?.classList.add('nav-light');
    });
    window.addEventListener('scroll', scrollNavigation, true);

    return () => {
      window.removeEventListener('scroll', scrollNavigation, true);
    };
  }, []);
  const errorHandler = () => {
    setError(null);
  };
  const successHandler = () => {
    setSuccess(null);
  };

  return (
    <React.Fragment>
      {/* Section So far DONE */}
      {error && (
        <CommonModal
          title={error.title}
          message={error.message}
          onClose={errorHandler}
          status="error"
        />
      )}

      {success && (
        <CommonModal
          title={success.title}
          message={success.message}
          onClose={successHandler}
          status="success"
        />
      )}
      {isLoading ? (
        <div id="preloader">
          <div id="status">
            <div className="spinner">
              <div className="double-bounce1"></div>
              <div className="double-bounce2"></div>
            </div>
          </div>
        </div>
      ) : (
        <>
          <section
            className="d-table w-100 pt-5 mt-5"
            style={{
              background: `url(${loginBackgroundBanner}) center center no-repeat`,
              backgroundSize: 'cover',
            }}
            id="userlogin"
          >
            <Container className="section-three">
              <Row className="position-relative align-items-center pt-5">
                <Col lg={6} className="offset-lg-6">
                  <Card
                    className="title-heading studio-home rounded shadow mb-5 "
                    style={{
                      marginBottom: '50px',
                      // backgroundColor: '#ffc107',
                    }}
                  >
                    <h3 className="text-center text-black fontSize20">
                      Login Account
                    </h3>

                    <Row>
                      <Form
                        onSubmit={(e) => {
                          e.preventDefault();
                          validation.handleSubmit();
                          return false;
                        }}
                        className="login-form"
                      >
                        <Row className="justify-content-center text-black">
                          <Col md={12} className="p-2">
                            <div className="loginname">
                              <h3 className="fontSize18">
                                Your Email <span>*</span>
                              </h3>
                              <div className="form-icon position-relative">
                                <i>
                                  <FeatherIcon
                                    icon="user"
                                    className="fea icon-sm icons"
                                  />
                                </i>
                              </div>
                              <Input
                                type="email"
                                className="form-control ps-5 form_Input "
                                name="username"
                                id="email"
                                required
                                placeholder="Email"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.username || ''}
                                invalid={
                                  validation.touched.username &&
                                  validation.errors.username
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.username &&
                              validation.errors.username ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.username}
                                </FormFeedback>
                              ) : null}
                            </div>
                          </Col>

                          <Col md={12} className="p-2">
                            <div className="">
                              <h3 className="fontSize18">
                                Password <span>*</span>
                              </h3>
                              <div className="form-icon position-relative">
                                <i>
                                  <FeatherIcon
                                    icon="key"
                                    className="fea icon-sm icons"
                                  />
                                </i>
                              </div>
                              <Input
                                type={showPassword ? 'text' : 'password'}
                                className="form-control ps-5 form_Input"
                                name="password"
                                id="password"
                                required
                                placeholder="Password"
                                onChange={(e) => setPassword(e.target.value)}
                                onBlur={validation.handleBlur}
                                defaultValue={validation.values.password || ''}
                                invalid={
                                  validation.touched.password &&
                                  validation.errors.password
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.password &&
                              validation.errors.password ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.password}
                                </FormFeedback>
                              ) : null}

                              <span
                                style={{
                                  position: 'absolute',
                                  right: '20px',
                                  top: '69%',
                                  transform: 'translateY(-50%)',
                                  cursor: 'pointer',
                                  color: `black`,
                                }}
                                onClick={togglePasswordVisibility}
                              >
                                {showPassword ? (
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="1.5em"
                                    height="1.5em"
                                    viewBox="0 0 24 24"
                                  >
                                    <path
                                      fill="currentColor"
                                      d="M21.92 11.6C19.9 6.91 16.1 4 12 4s-7.9 2.91-9.92 7.6a1 1 0 0 0 0 .8C4.1 17.09 7.9 20 12 20s7.9-2.91 9.92-7.6a1 1 0 0 0 0-.8M12 18c-3.17 0-6.17-2.29-7.9-6C5.83 8.29 8.83 6 12 6s6.17 2.29 7.9 6c-1.73 3.71-4.73 6-7.9 6m0-10a4 4 0 1 0 4 4a4 4 0 0 0-4-4m0 6a2 2 0 1 1 2-2a2 2 0 0 1-2 2"
                                    />
                                  </svg>
                                ) : (
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="1.5em"
                                    height="1.5em"
                                    viewBox="0 0 24 24"
                                  >
                                    <path
                                      fill="currentColor"
                                      d="M10.94 6.08A6.93 6.93 0 0 1 12 6c3.18 0 6.17 2.29 7.91 6a15.23 15.23 0 0 1-.9 1.64a1 1 0 0 0-.16.55a1 1 0 0 0 1.86.5a15.77 15.77 0 0 0 1.21-2.3a1 1 0 0 0 0-.79C19.9 6.91 16.1 4 12 4a7.77 7.77 0 0 0-1.4.12a1 1 0 1 0 .34 2ZM3.71 2.29a1 1 0 0 0-1.42 1.42l3.1 3.09a14.62 14.62 0 0 0-3.31 4.8a1 1 0 0 0 0 .8C4.1 17.09 7.9 20 12 20a9.26 9.26 0 0 0 5.05-1.54l3.24 3.25a1 1 0 0 0 1.42 0a1 1 0 0 0 0-1.42Zm6.36 9.19l2.45 2.45A1.81 1.81 0 0 1 12 14a2 2 0 0 1-2-2a1.81 1.81 0 0 1 .07-.52M12 18c-3.18 0-6.17-2.29-7.9-6a12.09 12.09 0 0 1 2.7-3.79L8.57 10A4 4 0 0 0 14 15.43L15.59 17A7.24 7.24 0 0 1 12 18"
                                    />
                                  </svg>
                                )}
                              </span>
                            </div>
                          </Col>

                          <Col md={12} className="p-2">
                            <div className="d-flex justify-content-between">
                              <div className="form-check font_size16">
                                <Input
                                  type="checkbox"
                                  className="form-check-input mt-2"
                                  id="flexCheckDefault"
                                  required
                                />I agree with 
                                <Link
                                  className=""
                                  // htmlFor="flexCheckDefault"
                                  to="/privacypolicy"
                                > Privacy Terms
                                </Link>
                              </div>
                            </div>
                          </Col>
                          <Col lg={12} className="mb-0">
                            <div className="d-flex justify-content-center ">
                              <button
                                className="btn "
                                style={
                                  // { padding: '5px 100px 5px 100px' }
                                  {
                                    width: '100%',
                                    background: '#0d6efd',
                                    color: 'white',
                                    fontSize: '20px',
                                    padding: '4px',
                                    borderRadius: '10px',
                                  }
                                }
                              >
                                {' '}
                                Sign-In{' '}
                              </button>
                            </div>
                          </Col>
                          <Col lg={12} className="mb-0">
                            <div className="d-flex justify-content-center mb-3">
                              <Link
                                className="btn  m-3"
                                style={{
                                  padding: '5px 50px',
                                  color: 'white',
                                  background: '#ff7f00',
                                }}
                                to={'/reset-password'}
                              >
                                {' '}
                                Forgot Password{' '}
                              </Link>
                            </div>
                          </Col>
                          <hr />
                          {/* <Col md={12} lg={12} className=" text-center mt-3">
                            <h3 className="fontSize14 ">Or Login With</h3>
                            <Row
                              className="p-2"
                              style={{ borderRadius: `10px` }}
                            >
                              <div className="mb-1">
                                <div className="">
                                  <Link
                                    to="#"
                                    className="text-dark d-flex justify-content-center bg-white mx-lg-5 py-1 shadow p-3 mb-3 bg-body-tertiary rounded"
                                  >
                                    <img
                                      src={googleIcon}
                                      width="24"
                                      className="mt-1"
                                    />{' '}
                                    &nbsp;&nbsp;&nbsp;&nbsp;
                                    <h3 className="fontSize14 mt-2">
                                      Sign in with Google
                                    </h3>
                                  </Link>
                                </div>
                              </div>

                              <div className="">
                                <div className="">
                                  <Link
                                    to=""
                                    className="text-white d-flex align-items-center justify-content-center mx-lg-5 py-1 shadow p-3 mb-2 bg-body-tertiary rounded"
                                    style={{ backgroundColor: '#0a66c2' }}
                                  >
                                    <i
                                      className="fa fa-linkedin-square fa-2x position-static text-white"
                                      aria-hidden="true"
                                    ></i>{' '}
                                    &nbsp;&nbsp;&nbsp;&nbsp;
                                    <h3 className="fontSize14 mt-2">
                                      Sign in with Linkedin
                                    </h3>
                                  </Link>
                                </div>
                              </div>
                            </Row>
                          </Col> */}
                          <Col xs={10} className="text-center">
                            <p className="mb-0 mt-2">
                              <large className="mt-2 fontSize16">
                                Don't have an account yet ?
                              </large>{' '}
                              <Link
                                to="/user-registration-page"
                                className="fontSize16"
                              >
                                {'  '}&nbsp;&nbsp; Sign Up Here
                              </Link>
                            </p>
                          </Col>
                        </Row>
                      </Form>
                    </Row>
                  </Card>
                </Col>
              </Row>
            </Container>
          </section>
        </>
      )}
    </React.Fragment>
  );
};

export default UserLoginPageFunction;
