import React, { Component } from 'react';
import { Link, json } from 'react-router-dom';
import { Navigate } from 'react-router-dom';

import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import ProfileModal from './Profile_modal';
import CommonModal from './modal';
import Sliderr, { Range } from 'rc-slider';
import 'rc-slider/assets/index.css';
import ContentLoader from 'react-content-loader';
import ReactPaginate from 'react-paginate';
import DataContext from '../Home/context';
import ConnectingIcon from '../../assets/images/MettaMateImages/connecting.png';
import { logoutFunction } from './logout';
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Label,
  Input,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  PaginationItem,
  PaginationLink,
  Fade,
  Progress,
} from 'reactstrap';

import 'bootstrap/dist/css/bootstrap.min.css';
import '../Home/css/userHomePage.css';
import 'react-phone-input-2/lib/style.css'; // Import the styles
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/material.css';
//Import Icons
import FeatherIcon from 'feather-icons-react';
import 'react-loading-skeleton/dist/skeleton.css';
import BackToTop from '../../components/Layout/backToTop';

import globeIcon from '../../assets/images/MettaMateImages/worldglobe.png';
import doctorIcon from '../../assets/images/MettaMateImages/doctor.png';
import engineerIcon from '../../assets/images/MettaMateImages/engineers.png';
import softwareEngIcon from '../../assets/images/MettaMateImages/Software-Engineer.png';
import lawyerIcon from '../../assets/images/MettaMateImages/lawyer.png';
import managementIcon from '../../assets/images/MettaMateImages/management.png';
import professorIcon from '../../assets/images/MettaMateImages/Teacher.png';
import scientistIcon from '../../assets/images/MettaMateImages/scientist.png';
import businessIcon from '../../assets/images/MettaMateImages/businessexecutives.png';
import financialIcon from '../../assets/images/MettaMateImages/financial-management-.png';
import manavatar from '../../assets/images/MettaMateImages/male_avatar.jpg';
import femaleavatar from '../../assets/images/MettaMateImages/female_avatar.jpeg';
import transgenderavatar from '../../assets/images/MettaMateImages/transgenderprofile.png';

import indiaIcon from '../../assets/images/MettaMateImages/flag.png';
import usaIcon from '../../assets/images/MettaMateImages/united-states-of-america.png';
import ausIcon from '../../assets/images/MettaMateImages/australia.png';
import ukIcon from '../../assets/images/MettaMateImages/united-kingdom.png';
import filterIcon from '../../assets/images/MettaMateImages/filter.png';
import communityicon from '../../assets/images/MettaMateImages/communitymembers.png';
import weddingicon from '../../assets/images/MettaMateImages/wedding.png';
import axios from 'axios';
import apiUrl from '../../apppages/server';
import MapFunction from './maps';

import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from 'react-places-autocomplete';
import ModalBodySkeleton from './skeleton';
import ChatBoxFunction from './chat/chatbox';

class ListProfilesSquareClass extends Component {
  // Declaring the enum as a static property of the class
  static MemberType = Object.freeze({
    ALLIANCE_SEEKER: 1,
    COMMUNITY_MEMBER: 4,
  });

  static contextType = DataContext;
  // Global numberOfProfilesPerPage definition to be used everywhere 
  static numberOfProfilesPerPage = 20;
  async componentDidMount() {
    this.progressBarUpdate();
    document.body.classList = '';
    document.querySelectorAll('#buyButton').forEach((navLink) => {
      navLink.classList.add('btn-light');
      navLink.classList.remove('btn-soft-primary');
    });
    window.addEventListener('scroll', this.scrollNavigation, true);

    let pageUrl = window.location.href.split('/');
    if (pageUrl[4]) {
      this.openModal(pageUrl[4]);
    }
    this.setState({
      pageUrl: apiUrl.replace('8080', '3000'),
    });


    await this.getAllMemberDetails(
      this.state.currentPage,
      ListProfilesSquareClass.MemberType.ALLIANCE_SEEKER,
    );

    window.scrollTo(0, 0);
    this.calculatePageNumbers();
    // this.updateCurrentItems();
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.scrollNavigation, true);
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.activeLink !== this.context.activeLink &&
      this.state.activeLink !== this.context.activeLink
    ) {
      this.setState(
        {
          membertypeid: ListProfilesSquareClass.MemberType.ALLIANCE_SEEKER,
          activeLink: this.context.activeLink,
          otherFilterRequestBody: {
            currentPage: 1,
            pageSize: this.numberOfProfilesPerPage,
            genderList: [],
            degreeLevelList: [],
            degreeNameList: [],
            jobProfessionList: [],
            religionList: [],
            locationidlist: [],
            serviceList: [],
            mentorshipList: [],
            minage: 0,
            maxage: 100,
            degreecollege: '',
            companyname: '',
            search: '',
          },
          showMapPage: false,
        },
        () => {
          this.getAllMemberDetails(
            1,
            this.context.activeLink,
          );
        },
      );
    }

    if (
      prevState.currentPage !== this.state.currentPage ||
      prevState.memberList !== this.state.memberList ||
      prevState.filterData !== this.state.filterData
    ) {
      this.calculatePageNumbers();
      // this.updateCurrentItems();
    }
  }
  progressBarUpdate = async () => {
    const configurationData = JSON.parse(
      localStorage.getItem('configurationData'),
    );
    var memberID = configurationData.memberuuid;

    try {
      //educational
      const educationalResponse = await axios.get(
        apiUrl + 'members/educational/' + memberID,
        {
          headers: {
            Authorization: 'Bearer' + ' ' + localStorage.getItem('token'),
            'Content-Type': 'application/json',
          },
        },
      );
      if (educationalResponse.data.length) {
        this.setState({
          profilePercentage: this.state.profilePercentage + 15,
        });
      }
      //personal
      const personalResponse = await axios.get(
        apiUrl + 'members/personal/' + memberID,
        {
          headers: {
            Authorization: 'Bearer' + ' ' + localStorage.getItem('token'),
            'Content-Type': 'application/json',
          },
        },
      );
      if (personalResponse.data[0] != null) {
        this.setState({
          profilePercentage: this.state.profilePercentage + 15,
        });
      }
      // occupation
      const occupationResponse = await axios.get(
        apiUrl + 'members/occupation/' + memberID,
        {
          headers: {
            Authorization: 'Bearer' + ' ' + localStorage.getItem('token'),
            'Content-Type': 'application/json',
          },
        },
      );
      if (occupationResponse.data.length) {
        this.setState({
          profilePercentage: this.state.profilePercentage + 15,
        });
      }
      //lifestyle
      const lifestyleResponse = await axios.get(
        apiUrl + 'members/lifestyle/' + memberID,
        {
          headers: {
            Authorization: 'Bearer' + ' ' + localStorage.getItem('token'),
            'Content-Type': 'application/json',
          },
        },
      );
      if (lifestyleResponse.data.length) {
        this.setState({
          profilePercentage: this.state.profilePercentage + 15,
        });
      }
      //family
      const familyResponse = await axios.get(
        apiUrl + 'members/family/' + memberID,
        {
          headers: {
            Authorization: 'Bearer' + ' ' + localStorage.getItem('token'),
            'Content-Type': 'application/json',
          },
        },
      );
      if (familyResponse.data.length) {
        this.setState({
          profilePercentage: this.state.profilePercentage + 15,
        });
      }
      this.setState({
        progressAlert: true,
        progressFadeAlert: true,
      });
    } catch (e) {
      console.log('error in updating progressbar', e);
    }
  };

  getAdvertisementDetails = async () => {
    try {
      const response = await axios.post(
        `${apiUrl}advertisement/getalladvertisement`,
        {
          pageNo: 1,
          pageSize: this.numberOfProfilesPerPage,
          search: '',
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        },
      );
      this.setState({ advertisementdata: response.data, isLoading: false });
      const a = advertisementdata.sort(() => Math.random() - 0.5);
      this.setState({ filterAdvertise: a });
    } catch (error) { }
  };

  getAllMemberDetails = async (currentPage, membertypeid) => {
    this.setState({ noDataFound: false, detailsType: 0 });
    if (this.state.noLoaderFlag == 1) {
      this.setState({ isLoading: true });
    }
    const { activeLink } = membertypeid;
    try {
      const response = await axios.post(
        `${apiUrl}members/getallmembers`,
        {
          pageNo: currentPage,
          pageSize: ListProfilesSquareClass.numberOfProfilesPerPage,
          membertypeid: membertypeid, // Backend checks the membertype field only.
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        },
      );
      let defaultLocation = "Location Not Provided"
      const allMembers = await Promise.all(
        response.data.details.map(async (member) => {
          try {
            if (member.currentlocationid) {
              const locationName = await this.getLocationName(
                member.currentlocationid,
              );
              member.currentlocationname = locationName || defaultLocation;
            } else {
              member.currentlocationname = defaultLocation;
            }
            return member;
          } catch (error) {
            console.error('Error fetching location details:', error);
            return member;
          }
        }),
      );

      this.setState({
        memberList: allMembers.filter((member) => member !== null),
        isLoading: false,
        totalPages: Math.ceil(response.data.totalCount / ListProfilesSquareClass.numberOfProfilesPerPage),
        totalCount: response.data.totalCount,
      });

      if (allMembers.length > 0) {
        this.setState({ noDataFound: false });
      }
    } catch (error) {
      if (error.response.status === 401) {
        this.setState({
          errorAlert: { title: 'Session Is Expired', message: 'Please Login.' },
        });
        await logoutFunction();
        setTimeout(() => {
          this.setState({ redirectToLogin: true });
        }, 2000);
      }
      this.setState({ noDataFound: true });
    }
    if (this.state.configurationData.memberstatuscode === 3) {
      this.setState({ showAlert: true, fadeAlert: true });
    } else {
      this.setState({ showAlert: false, fadeAlert: false });
    }
  };

  scrollNavigation = () => {
    var doc = document.documentElement;
    var top = (window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0);
    if (top > 80) {
      document.querySelector('.shoppingbtn')?.classList.remove('btn-light');
      document.querySelector('.settingbtn')?.classList.remove('btn-light');
      document.querySelector('.shoppingbtn')?.classList.add('btn-primary');
      document.querySelector('.settingbtn')?.classList.add('btn-soft-primary');
      document.getElementById('topnav')?.classList.add('nav-sticky');
    } else {
      document.querySelector('.shoppingbtn')?.classList.remove('btn-primary');
      document
        .querySelector('.settingbtn')
        ?.classList.remove('btn-soft-primary');
      document.querySelector('.shoppingbtn')?.classList.add('btn-light');
      document.querySelector('.settingbtn')?.classList.add('btn-light');
      document.getElementById('topnav')?.classList.remove('nav-sticky');
    }
  };

  calculate_age = (dob1) => {
    if (dob1 != null) {
      var today = new Date();
      var birthDate = new Date(dob1); // create a date object directly from `dob1` argument
      var age_now = today.getFullYear() - birthDate.getFullYear();
      var m = today.getMonth() - birthDate.getMonth();
      if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age_now--;
      }
      return age_now ? age_now : '00';
    } else {
      return '00';
    }
  };

  handlerecommenderCountrycodechange = (value, country) => {
    this.setState(
      (prevState) => ({
        countrycode: country.dialCode,
        phoneNumber: value.replace(prevState.countrycode, ''),
      }),
      () => { },
    );
  };

  changeHandler = (e) => {
    const valueselect = e.target.value;
    this.setState({ valueselect });
  };

  constructor(props) {
    super(props);

    this.state = {
      filterData: null,
      profilePercentage: 15,
      currentPage: 1,
      totalPages: 0,
      totalCount: 0,
      currentItems: null,
      pageNumbers: [],
      showAlert: false,
      fadeAlert: false,
      progressAlert: false,
      progressFadeAlert: false,
      detailsType: 0, // 0-allmember, 1- occupation, 2- customfilter, 3-country
      noLoaderFlag: 1,
      advertisementdata: [],
      activeFilterLink: '',
      activeLink: '',
      chatMemberData: {
        memberuuid: '',
        firstname: '',
        lastname: '',
        gendercode: 0,
        memberstatuscode: 0,
        memberstatusname: '',
        gendername: '',
        photopath1: '',
        photopath2: '',
        photopath3: '',
        subscription: '',
        jobprofession: '',
        linkedin: '',
        instagram: '',
        facebook: '',
        twitter: '',
        youtube: '',
        snapchat: '',
        recommended: 0,
        searchItem: '',
      },
      valueselect: 'email',
      memberList: [],
      totalCount: 0,
      currentPage: 1,
      currentPageNo: 1,
      pageSize: ListProfilesSquareClass.numberOfProfilesPerPage,
      favoritemembervisible: false,
      serviceList: [],
      filterdata: [
        // { image: trendingIcon, filterName: 'Trending', value: 0, type: 'profession' },
        {
          image: globeIcon,
          filterName: 'Location',
          value: 10,
          type: 'location',
        },
        {
          image: doctorIcon,
          filterName: 'Doctors',
          value: 1,
          type: 'profession',
        },
        {
          image: engineerIcon,
          filterName: 'Engineers',
          value: 4,
          type: 'profession',
        },
        {
          image: softwareEngIcon,
          filterName: 'Software Engineers',
          value: 3,
          type: 'profession',
        },
        {
          image: lawyerIcon,
          filterName: 'Lawyers',
          value: 2,
          type: 'profession',
        },
        {
          image: professorIcon,
          filterName: 'Professors',
          value: 9,
          type: 'profession',
        },
        {
          image: managementIcon,
          filterName: 'Management',
          value: 6,
          type: 'profession',
        },
        {
          image: scientistIcon,
          filterName: 'Scientist',
          value: 5,
          type: 'profession',
        },
        {
          image: financialIcon,
          filterName: 'Financial Executives',
          value: 7,
          type: 'profession',
        },
        {
          image: businessIcon,
          filterName: 'Business Executives',
          value: 8,
          type: 'profession',
        },
        { image: usaIcon, filterName: 'USA', type: 'country', value: 'US' },
        { image: ukIcon, filterName: 'UK', type: 'country', value: 'UK' },
        { image: indiaIcon, filterName: 'India', type: 'country', value: 'IN' },
        { image: ausIcon, filterName: 'AUS', type: 'country', value: 'AU' },
      ],
      configurationData: JSON.parse(localStorage.getItem('configurationData')),
      modalOpen: false,
      filtermodalOpen: false,
      confirmmodalOpen: false,
      confirmConnectionmodalOpen: false,
      mutualonnectionmodalOpen: false,
      likedmemberuuid: null,
      likedStatus: '',
      copyModalOpen: false,
      copyButton: 'Copy Url',
      sharedMemberUuid: '',
      selectedMemberUUID: null,
      membersFullData: [],
      dob: '',
      nativeLocation: '',
      currentLocation: '',
      companyLocation: '',
      degreeLevelList: [],
      degreeNameList: [],
      genderList: [],
      religionList: [],
      occupationList: [],
      // sbPartnerserviceList: [],
      showAllDegreeNames: false,
      showAllOccupation: false,
      currentaddress: '',
      selectedPlaces: [],
      selectedPlacesid: [],
      nativeaddress: '',
      nativeselectedPlaces: [],
      successAlert: '',
      errorAlert: '',
      pageUrl: '',
      mutualConnectionsData: [],
      rangeValues: [0, 100],
      otherFilterRequestBody: {
        currentPage: 1,
        pageSize: ListProfilesSquareClass.numberOfProfilesPerPage,
        genderList: [],
        degreeLevelList: [],
        degreeNameList: [],
        jobProfessionList: [],
        religionList: [],
        locationidlist: [],
        serviceList: [],
        mentorshipList: [],
        minage: 0,
        maxage: 100,
        occupationList: [],
        skillsetList: [],
        search: '',
        degreecollege: '',
        companyname: '',
        membertypeid: ListProfilesSquareClass.MemberType.ALLIANCE_SEEKER,
      },
      redirectToSubscriptionPlan: false,
      redirectToLogin: false,
      isLoading: true,
      isModalLoading: true,
      noDataFound: false,
      showMapPage: false,
    };
  }
  goToIndex(newIndex) {
    this.setState({ activeIndex: newIndex });
  }
  // carousel functionality

  professionLink = async (link, filterValue, filterType) => {
    this.setState({
      activeFilterLink: link,
      isLoading: true,
      showMapPage: false,
      currentPage: 1,
      currentPageNo: 1,
    });
    try {
      if (filterType == 'profession') {
        let request = {
          currentPage: this.state.currentPage,
          pageSize: ListProfilesSquareClass.numberOfProfilesPerPage,
          occupationacategoryid: filterValue,
          membertypeid: ListProfilesSquareClass.MemberType.ALLIANCE_SEEKER,
        };

        let response = await axios.post(
          `${apiUrl}members/getmembersbyoccupation`,
          request,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
          },
        );
        const allMembers = await Promise.all(
          response.data.details.map(async (member) => {
            try {
              if (member.currentlocationid) {
                const locationName = await this.getLocationName(
                  member.currentlocationid,
                );
                member.currentlocationname = locationName || 'Unknown Location';
              } else {
                member.currentlocationname = 'Unknown Location';
              }
              return member;
            } catch (error) {
              console.error('Error fetching location details:', error);
              return member;
            }
          }),
        );
        this.updateCurrentItems(1, allMembers);
        this.setState({
          detailsType: 1,
          filterData: allMembers.filter((member) => member !== null),
          isLoading: false,
          totalPages: Math.ceil(response.data.totalCount / ListProfilesSquareClass.numberOfProfilesPerPage),
          totalCount: response.data.totalCount,
        });
      } else if (filterType == 'country') {
        let request = {
          currentPage: 1,
          pageSize: ListProfilesSquareClass.numberOfProfilesPerPage,
          occupationacategoryid: filterValue,
          membertypeid: ListProfilesSquareClass.MemberType.ALLIANCE_SEEKER,
        };
        let response = await axios.post(
          `${apiUrl}members/getmembersbycountry`,
          request,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
          },
        );
        const allMembers = await Promise.all(
          response.data.details.map(async (member) => {
            try {
              if (member.currentlocationid) {
                const locationName = await this.getLocationName(
                  member.currentlocationid,
                );
                member.currentlocationname = locationName || 'Unknown Location';
              } else {
                member.currentlocationname = 'Unknown Location';
              }
              return member;
            } catch (error) {
              console.error('Error fetching location details:', error);
              return member;
            }
          }),
        );
        this.updateCurrentItems(1, allMembers);
        this.setState({
          detailsType: 3,
          filterData: allMembers.filter((member) => member !== null),
          isLoading: false,
          totalPages: Math.ceil(response.data.totalCount / ListProfilesSquareClass.numberOfProfilesPerPage),
          totalCount: response.data.totalCount,
        });
        if (allMembers.length > 0) {
          // this.setState({ noDataFound: false, memberList: [] })
        } else if (allMembers.length == 0) {
          this.setState({ noDataFound: true });
        }
      } else if (filterType == 'location') {
        this.setState({ isLoading: false, showMapPage: true, memberList: [] });
      }
    } catch (error) {
      console.error('Error fetching members:', error);
      this.setState({ noDataFound: true, memberList: [] });
    }
  };

  favoriteMember = async () => {
    this.setState({
      favoritemembervisible: true,
      confirmmodalOpen: false,
      noLoaderFlag: 0,
    });
    try {
      let request = {
        likedmemberuuid: this.state.likedmemberuuid,
        memberuuid: localStorage.getItem('memberID'),
        membertypeid: ListProfilesSquareClass.MemberType.ALLIANCE_SEEKER,
      };
      let result = await axios
        .post(`${apiUrl}members/favourites`, request, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        })
        .then(async (res) => {
          this.getAllMemberDetails(
            this.state.currentPage,
            ListProfilesSquareClass.MemberType.ALLIANCE_SEEKER,
          );
        });
    } catch (error) { }
  };
  removeFromfavorite() {
    this.setState({ favoritemembervisible: false });
  }
  getLocationName = async (placeId) => {
    try {
      const service = new window.google.maps.places.PlacesService(
        document.createElement('div'),
      );
      return new Promise((resolve, reject) => {
        service.getDetails({ placeId }, (place, status) => {
          if (status === 'OK') {
            const address = place.formatted_address;
            resolve(address);
          } else {
            reject(status);
          }
        });
      });
    } catch (error) {
      console.error('Error fetching place details:', error);
      return '';
      // throw error;
    }
  };
  openModal = async (memberUUID) => {
    if (this.state.configurationData.subscription != 'Basic') {
      this.setState({
        isModalLoading: true,
        modalOpen: true,
        selectedMemberUUID: memberUUID,
        membersFullData: [],
      });
      try {
        const result = await axios.get(
          `${apiUrl}members/fulldetails/${memberUUID}`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
          },
        );
        const { data } = result;
        if (data && data.length > 0) {
          const member = data[0];
          const date = new Date(member.dob);
          const formattedDate = `${this.getOrdinalSuffix(date.getDate())} ${this.getMonthName(date.getMonth())}, ${date.getFullYear()}`;
          const nativeLocation = member.nativelocationid
            ? await this.handlePlaceId(member.nativelocationid)
            : 'Unknown Location';
          const currentLocation = member.currentlocationid
            ? await this.handlePlaceId(member.currentlocationid)
            : 'Unknown Location';
          const companyLocation = member.companylocationid
            ? await this.handlePlaceId(member.companylocationid)
            : 'Unknown Location ';

          this.setState({
            dob: formattedDate,
            nativeLocation,
            currentLocation,
            companyLocation,
            membersFullData: member,
            isModalLoading: false,
          });
        }
      } catch (error) {
        console.error('Error fetching member details: ', error);
      }
    } else {
      this.setState({ redirectToSubscriptionPlan: true });
    }
  };
  /*
  openConnectionModal = async (connectedmemberuuid, isConnected) => {
    if (
      this.state.configurationData.subscription != 'Basic' &&
      isConnected == ''
    ) {
      this.setState({
        confirmConnectionmodalOpen: true,
        selectedMemberUUID: connectedmemberuuid,
      });
    } else if (
      (this.state.configurationData.subscription == 'Gold' ||
        this.state.configurationData.subscription == 'Platinum') &&
      (isConnected == 'Connecting' || isConnected == 'Connected')
    ) {
    } else if (this.state.configurationData.subscription == 'Basic') {
      this.setState({ redirectToSubscriptionPlan: true });
    }
  };*/

  requestForConnection = async () => {
    try {
      let request = {
        memberuuid: localStorage.getItem('memberID'),
        connectedmemberuuid: this.state.selectedMemberUUID,
      };
      let result = await axios
        .post(`${apiUrl}connection/requestforconnection`, request, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        })
        .then(async (res) => {
          this.setState({
            successAlert: { title: 'SUCCESS!', message: res.data.message },
            noLoaderFlag: 1,
          });
          this.getAllMemberDetails(
            this.state.currentPage,
            ListProfilesSquareClass.MemberType.ALLIANCE_SEEKER,
          );
          this.setState({ selectedMemberUUID: '' });
          this.setState({ confirmConnectionmodalOpen: false });
        });
    } catch (error) {
      this.setState({
        errorAlert: { title: 'An Error Occured!', message: error.message },
      });
    }
  };

  openConfirmModal = (memberUUID, likedstatus) => {
    if (this.state.configurationData.subscription != 'Basic') {
      if (likedstatus === 1) {
        this.setState({
          confirmmodalOpen: true,
          likedmemberuuid: memberUUID,
          likedStatus: 'Unlike',
        });
      } else {
        this.setState({
          confirmmodalOpen: true,
          likedmemberuuid: memberUUID,
          likedStatus: 'Like',
        });
      }
    } else {
      this.setState({ redirectToSubscriptionPlan: true });
    }
  };
  getOrdinalSuffix(day) {
    const suffixes = ['th', 'st', 'nd', 'rd'];
    const v = day % 100;
    return day + (suffixes[(v - 20) % 10] || suffixes[v] || suffixes[0]);
  }
  getMonthName(month) {
    const months = [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec',
    ];
    return months[month];
  }
  handlePlaceId = async (placeId) => {
    try {
      return new Promise((resolve, reject) => {
        const service = new window.google.maps.places.PlacesService(
          document.createElement('div'),
        );
        service.getDetails({ placeId }, (place, status) => {
          if (status === 'OK' && place && place.formatted_address) {
            const address = place.formatted_address;
            resolve(address);
          } else {
            reject(new Error('Place details not found'));
          }
        });
      });
    } catch (error) {
      console.error('Error fetching place details:', error);
      throw error;
    }
  };
  closeModal = () => {
    this.setState({
      modalOpen: false,
      selectedMemberUUID: null,
    });
  };


  openfilterModal = async () => {
    this.setState({
      filtermodalOpen: true,
      activeFilterLink: 'other',
    });
    try {
      const [
        degreelevelRes,
        degreenamesRes,
        genderRes,
        religionRes,
        occupationRes,
      ] = await Promise.all([
        axios.get(`${apiUrl}codes/degreelevel`),
        axios.get(`${apiUrl}codes/degreenames`),
        axios.get(`${apiUrl}codes/gender`),
        axios.get(`${apiUrl}codes/religion`),
        axios.get(`${apiUrl}codes/occupationalcategory`),
      ]);
      this.setState({
        degreeLevelList: degreelevelRes.data.list,
        degreeNameList: degreenamesRes.data.list,
        genderList: genderRes.data.list,
        religionList: religionRes.data.list,
        occupationList: occupationRes.data.list,
      });
      // this.sbPartnerserviceList()
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  handleDegreeLevel = (isChecked, response) => {
    this.setState((prevState) => {
      let degreeLevelList;
      if (isChecked) {
        if (
          !this.state.otherFilterRequestBody.degreeLevelList.includes(response)
        ) {
          degreeLevelList = [
            ...prevState.otherFilterRequestBody.degreeLevelList,
            response,
          ];
        }
      } else {
        degreeLevelList =
          prevState.otherFilterRequestBody.degreeLevelList.filter(
            (degreeLevel) => degreeLevel !== response,
          );
      }
      return {
        otherFilterRequestBody: {
          ...prevState.otherFilterRequestBody,
          degreeLevelList: degreeLevelList,
        },
      };
    });
  };

  handleDegreeNames = (isChecked, response) => {
    this.setState((prevState) => {
      let degreeNameList;
      if (isChecked) {
        if (
          !this.state.otherFilterRequestBody.degreeNameList.includes(response)
        ) {
          degreeNameList = [
            ...prevState.otherFilterRequestBody.degreeNameList,
            response,
          ];
        }
      } else {
        degreeNameList = prevState.otherFilterRequestBody.degreeNameList.filter(
          (degreeName) => degreeName !== response,
        );
      }
      return {
        otherFilterRequestBody: {
          ...prevState.otherFilterRequestBody,
          degreeNameList: degreeNameList,
        },
      };
    });
  };

  handleOccupation = (isChecked, response) => {
    this.setState((prevState) => {
      const otherFilterRequestBody = { ...prevState.otherFilterRequestBody };
      const occupationList = otherFilterRequestBody.occupationList || []; // Initialize if undefined

      let updatedOccupationList;
      if (isChecked) {
        if (!occupationList.includes(response)) {
          updatedOccupationList = [...occupationList, response];
        }
      } else {
        updatedOccupationList = occupationList.filter(
          (occupation) => occupation !== response,
        );
      }

      otherFilterRequestBody.occupationList = updatedOccupationList;

      return {
        otherFilterRequestBody: otherFilterRequestBody,
      };
    });
  };

  handleGender = (isChecked, response) => {
    this.setState((prevState) => {
      let updatedGenderList;
      if (isChecked) {
        if (!this.state.otherFilterRequestBody.genderList.includes(response)) {
          updatedGenderList = [
            ...prevState.otherFilterRequestBody.genderList,
            response,
          ];
        }
      } else {
        updatedGenderList = prevState.otherFilterRequestBody.genderList.filter(
          (gender) => gender !== response,
        );
      }
      return {
        otherFilterRequestBody: {
          ...prevState.otherFilterRequestBody,
          genderList: updatedGenderList,
        },
      };
    });
  };

  handleReligion = (isChecked, response) => {
    if (isChecked) {
      this.state.otherFilterRequestBody.religionList.push(response);
    } else {
      this.state.otherFilterRequestBody.religionList.splice(
        this.state.otherFilterRequestBody.religionList.findIndex(
          (x) => response === x,
        ),
        1,
      );
    }
  };

  handleServices = (isChecked, response) => {
    if (isChecked) {
      this.state.otherFilterRequestBody.serviceList.push(response);
    } else {
      this.state.otherFilterRequestBody.serviceList.splice(
        this.state.otherFilterRequestBody.serviceList.findIndex(
          (x) => response === x,
        ),
        1,
      );
    }
  };

  handleRangeChange = (values) => {
    this.setState({ rangeValues: values });
    this.setState({
      otherFilterRequestBody: {
        ...this.state.otherFilterRequestBody,
        minage: values[0],
        maxage: values[1],
      },
    });
  };

  otherFilterApply = async () => {
    const { otherFilterRequestBody } = this.state;
    const selectedPlacesIdOnly = this.state.selectedPlaces.map(
      (place) => place.placeid,
    );
    const updatedRequestBody = {
      ...otherFilterRequestBody,
      locationidlist: selectedPlacesIdOnly,
    };

    this.setState({
      filtermodalOpen: false,
      isLoading: true,
      noDataFound: false,
      showMapPage: false,
      detailsType: 2,
      currentPage: 1,
      currentPageNo: 1,
    });
    //alert(updatedRequestBody);
    // alert(JSON.stringify(updatedRequestBody, null, 2));
    try {
      await axios
        .post(`${apiUrl}members/getmembersbyallfilter`, updatedRequestBody, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        })
        .then(async (res) => {
          const allMembers = await Promise.all(
            res.data.details.map(async (member) => {
              try {
                if (member.currentlocationid) {
                  const locationName = await this.getLocationName(
                    member.currentlocationid,
                  );
                  member.currentlocationname =
                    locationName || 'Unknown Location';
                } else {
                  member.currentlocationname = 'Unknown Location';
                }
                return member;
              } catch (error) {
                console.error('Error fetching location details:', error);
                return member;
              }
            }),
          );

          const filteredMembers = allMembers.filter(
            (member) => member !== null,
          );
          this.updateCurrentItems(1, allMembers);
          this.setState({
            // memberList: filteredMembers,
            filterData: filteredMembers,
            isLoading: false,
            currentPage: 1,
            currentPageNo: 1,
            totalPages: Math.ceil(res.data.totalCount / ListProfilesSquareClass.numberOfProfilesPerPage),
            totalCount: res.data.totalCount,
          });
          if (allMembers.length > 0) {
            this.setState({ noDataFound: false });
          }
        })
        .catch((error) => {
          this.setState({ memberList: [], noDataFound: true });
          console.error('Error fetching members:', error);
        });
    } catch (error) {
      console.error('Error fetching members:', error);
    }
  };
  closeFilterModal = () => {
    this.setState({
      filtermodalOpen: false,
    });
  };
  closelikeModal = () => {
    this.setState({
      confirmmodalOpen: false,
    });
  };
  closeConnectionModal = () => {
    this.setState({
      confirmConnectionmodalOpen: false,
    });
  };
  closemutualModal = () => {
    this.setState({
      mutualonnectionmodalOpen: false,
    });
  };
  openCopyModal = (memberdata) => {
    this.setState({
      copyModalOpen: true,
      sharedMemberUuid: memberdata.memberuuid,
    });
  };
  toggleCopyModal = () => {
    this.setState((prevState) => ({
      copyModalOpen: !prevState.copyModalOpen,
    }));
  };

  toggleShowAllDegreeNames = () => {
    this.setState((prevState) => ({
      showAllDegreeNames: !prevState.showAllDegreeNames,
    }));
  };
  toggleShowAllOccupation = () => {
    this.setState((prevState) => ({
      showAllOccupation: !prevState.showAllOccupation,
    }));
  };
  toggleShowAllReligion = () => {
    this.setState((prevState) => ({
      showAllreligion: !prevState.showAllreligion,
    }));
  };
  toggleShowAllServices = () => {
    this.setState((prevState) => ({
      showallservice: !prevState.showallservice,
    }));
  };
  handleCurrentAddressChange = (address) => {
    this.setState({ currentaddress: address });
  };
  handleCurrentAddressSelect = (address, placeid) => {
    this.setState((prevState) => ({
      currentaddress: '',
      selectedPlaces: [
        ...prevState.selectedPlaces,
        { address: address, placeid: placeid },
      ],
    }));
  };
  handleRemovePlace = (index) => {
    this.setState((prevState) => ({
      selectedPlaces: prevState.selectedPlaces.filter(
        (place, i) => i !== index,
      ),
      selectedPlacesid: prevState.selectedPlacesid.filter(
        (place, i) => i !== index,
      ),
    }));
  };
  errorHandler = () => {
    this.setState({ errorAlert: null });
  };
  successHandler = () => {
    this.setState({ successAlert: null });
  };
  displayImage = (imagepath, gender) => {
    if (!imagepath) {
      if (gender == 'Male') {
        return manavatar;
      } else if (gender == 'Female') {
        return femaleavatar;
      } else if (gender == 'Other') {
        return transgenderavatar;
      }
    } else {
      return imagepath;
    }
  };
  handleCopyText = () => {
    const urlToCopy = `${this.state.pageUrl}index/${this.state.sharedMemberUuid}`;
    const textArea = document.createElement('textarea');
    textArea.value = urlToCopy;
    document.body.appendChild(textArea);
    textArea.select();
    document.execCommand('copy');
    document.body.removeChild(textArea);
    this.setState({ copyButton: 'Copied!' });
    setTimeout(() => {
      this.setState({ copyButton: 'Copy Url' });
    }, 3000);
  };

  tipFormatter = (value) => {
    return `${value}`;
  };

  openShortChat = (memberdata) => {
    // try {
    this.setState({ chatMemberData: memberdata });
    if (document.getElementById('chatbox').style.display == 'none') {
      document.getElementById('chatbox').style.display = '';
    } else {
    }
  };

  closeShortChat = () => {
    document.getElementById('chatbox').style.display = 'none';
  };
  handleAlert = () => {
    this.setState({ fadeAlert: false });
    setTimeout(() => {
      this.setState({ showAlert: false });
    }, 800);
  };
  handleAlertProgress = () => {
    this.setState({ progressFadeAlert: false });
    setTimeout(() => {
      this.setState({ progressAlert: false });
    }, 800);
  };
  handlePageClick = (pageNumber) => {
    if (
      this.state.currentPageNo == 0 ||
      this.state.totalPages + 1 == pageNumber
    ) {
      return;
    }
    this.setState({
      currentPageNo: pageNumber,
      currentPage: pageNumber,
    });
    if (this.state.currentItems) this.updateCurrentItems(pageNumber);
    else this.getAllMemberDetails(pageNumber, ListProfilesSquareClass.MemberType.ALLIANCE_SEEKER);

  };

  updateCurrentItems = (currentPage, data) => {
    const { pageSize, filterData } = this.state;
    const indexOfLastItem = currentPage * pageSize;
    const indexOfFirstItem = indexOfLastItem - pageSize;
    let currentItems = [];
    if (data) {
      currentItems = data.slice(indexOfFirstItem, indexOfLastItem);
    } else {
      currentItems = filterData.slice(indexOfFirstItem, indexOfLastItem);
    }
    this.setState({ currentItems });
  };

  calculatePageNumbers = () => {
    const { currentPage, totalPages } = this.state;
    //console.log(totalPages);
    const pages = [];

    // Logic to decide when to show the first page and ellipses
    if (currentPage > 2) {
      pages.push(1);
      if (currentPage > 3) {
        pages.push('...'); // Unique key for the first ellipsis
      }
    }

    // Determine the range of page numbers to show around the current page
    let startPage = Math.max(1, currentPage - 1);
    let endPage = Math.min(totalPages, currentPage + 1);

    for (let i = startPage; i <= endPage; i++) {
      pages.push(i);
    }

    // Logic to decide when to show ellipses and the last page
    if (currentPage < totalPages - 1) {
      if (currentPage < totalPages - 2) {
        pages.push('....'); // Unique key for the second ellipsis
      }
      pages.push(totalPages);
    }
    this.setState({ pageNumbers: pages });
  };

  handleSearchItem = async () => {
    const { searchItem } = this.state;
    if (!searchItem) return;
    try {
      this.setState({ isLoading: true });
      const response = await axios.get(
        apiUrl + `members/getmembersbysearch/${searchItem}`,
        {
          headers: {
            Authorization: 'Bearer' + ' ' + localStorage.getItem('token'),
            'Content-Type': 'application/json',
          },
        },
      );
      await response.data.map(async (member) => {
        if (member.currentlocationid) {
          const locationName = await this.getLocationName(
            member.currentlocationid,
          );
          member.currentlocationname = locationName || 'Unknown Location';
        }
        console.log(this.state.currentItems);
      });

      this.setState({
        memberList: response.data,
        isLoading: false,
        totalPages: Math.ceil(response.data.totalCount / this.state.pageSize),
        totalCount: response.data.totalCount,
      });
      if (response.data.length > 0) {
        this.setState({ noDataFound: false });
      }
    } catch (error) {
      console.error(error);
    } finally {
      this.setState({ isLoading: false });
    }
  };

  render() {
    if (this.state.redirectToSubscriptionPlan) {
      return <Navigate to="/subscriptionPlan" />;
    }
    if (this.state.redirectToLogin) {
      return <Navigate to="/user-login-page" />;
    }

    const { activeLink } = this.context;
    const settings = {
      dots: false,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: true,
    };
    const filterslider = {
      dots: false,
      infinite: false,
      speed: 500,
      slidesToShow: 6, // Show 5 items at a time
      slidesToScroll: 1,
      initialSlide: 0,
      arrows: true,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 4,
            slidesToScroll: 1,
            infinite: true,
            dots: false,
          },
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
            initialSlide: 2,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    };
    const {
      showAllDegreeNames,
      currentaddress,
      selectedPlaces,
      nativeaddress,
      nativeselectedPlaces,
      showAllreligion,
      showallservice,
      showAllOccupation,
    } = this.state;
    const visibleDegreeNameList = showAllDegreeNames
      ? this.state.degreeNameList
      : this.state.degreeNameList.slice(0, 3);
    const visiblereligionList = showAllreligion
      ? this.state.religionList
      : this.state.religionList.slice(0, 3);
    const visibleOccupationList = showAllOccupation
      ? this.state.occupationList
      : this.state.occupationList.slice(0, 3);

    const { serviceList } = this.state;
    const showAllService = serviceList.length > 3;
    const { isLoading, isModalLoading, advertisementdata } = this.state;
    const {
      currentItems,
      pageNumbers,
      currentPage,
      totalPages,
      currentPageNo,
    } = this.state;

    return (
      <React.Fragment>
        {this.state.errorAlert && (
          <CommonModal
            title={this.state.errorAlert.title}
            message={this.state.errorAlert.message}
            onClose={this.errorHandler}
            status="error"
          />
        )}

        {this.state.successAlert && (
          <CommonModal
            title={this.state.successAlert.title}
            message={this.state.successAlert.message}
            onClose={this.successHandler}
            status="success"
          />
        )}
        {/* breadcrumb */}
        <div className="contianer-fluid half-screen-background">
          <div className="container">
            <Row style={{ marginTop: '5.5rem' }}>
              {this.state.showAlert && (
                <Fade in={this.state.fadeAlert}>
                  <div className="alert alert-light">
                    <Row>
                      <div className="text-end">
                        <i
                          className="fa fa-times text-end start-0 z-index-1 dissmissicon position-relative"
                          aria-hidden="true"
                          aria-controls="example-fade-text"
                          aria-expanded={this.state.fadeAlert}
                          onClick={() => this.handleAlert()}
                        ></i>
                      </div>
                      <Col md={6} className="px-4 mb-2">
                        <div className="icon ">
                          <img
                            src={weddingicon}
                            alt="img"
                            className="popupImg"
                          />
                          <h3>Allaince Seeker</h3>
                        </div>

                        <p className="para-desc">
                          MettaMate is a trusted connected network. Each element
                          of the network is verified and known using
                          recommendations only.
                        </p>
                        <p className="para-desc para-desc fontSize18 fw-bold">
                          Please ping your recommender to approve your joining
                          request. Then you can update your profile and search
                          your preferred matches or help community member to
                          match alliances !
                        </p>
                        <div className="">
                          <button
                            type="button"
                            className="btn btn-outline-primary me-2"
                            onClick={() => {
                              '/contactus';
                            }}
                          >
                            <span className="d-flex">
                              <i>
                                <FeatherIcon
                                  icon="contact"
                                  className="fea icon-sm icons px-1"
                                  style={{ width: `30px` }}
                                />
                              </i>
                              <span className="d-none d-md-block">
                                Contact Us{' '}
                              </span>
                            </span>
                          </button>
                        </div>
                      </Col>
                      <Col md={6} className="px-4 mb-2">
                        <div className="icon">
                          <img
                            src={communityicon}
                            alt="img"
                            className="popupImg"
                          />
                          <h3>Community Members</h3>
                        </div>
                        <p className="para-desc fontSize18">
                          MettaMate is a trusted connected network. Each element
                          of the network is verified and known using
                          recommendations only.
                        </p>
                        <p className="para-desc fontSize18 fw-bold">
                          Please ping your recommender to approve your joining
                          request. Then you can update your profile and search
                          your preferred matches or help community member to
                          match alliances !
                        </p>
                        <div className="">
                          <button
                            type="button"
                            className="btn btn-outline-primary me-2"
                            onClick={() => {
                              '/contactus';
                            }}
                          >
                            <span className="d-flex">
                              <i>
                                <FeatherIcon
                                  icon="contact"
                                  className="fea icon-sm icons px-1"
                                  style={{ width: `30px` }}
                                />
                              </i>
                              <span className="d-none d-md-block">
                                Contact Us{' '}
                              </span>
                            </span>
                          </button>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </Fade>
              )}
            </Row>
          </div>
          <div className="container mb-1">
            {this.state.profilePercentage < 90 && this.state.progressAlert && (
              <Fade in={this.state.progressFadeAlert}>
                <div className="text-end" style={{ marginTop: '-19px' }}>
                  <i
                    className="fa fa-times text-end start-0 z-index-1 prog-dismiss position-relative"
                    style={{ top: '32px' }}
                    aria-hidden="true"
                    aria-controls="example-fade-text"
                    aria-expanded={this.state.progressAlert}
                    onClick={() => this.handleAlertProgress()}
                  ></i>
                </div>
                <Row className="inner_row mx-0 row mb-3 p-3">
                  <Col md={12}>
                    {this.state.profilePercentage == 100 ? (
                      <></>
                    ) : (
                      <div className="progress-box">
                        <h6 className="title ">
                          Profile Completion ({this.state.profilePercentage}
                          %)
                        </h6>
                        <Progress
                          value={this.state.profilePercentage}
                          color="primary"
                          barClassName="position-relative"
                        >
                          <div
                            className="progress-value d-block text-muted h6"
                            style={{ margin: '-5px' }}
                          ></div>
                        </Progress>
                      </div>
                    )}
                  </Col>
                </Row>
              </Fade>
            )}

            <section
              className={`section_user_home_page ${this.state.configurationData.memberstatuscode === 3 || this.state.configurationData.memberstatuscode === 0 ? 'pointer_event_none' : ''}`}
            >
              <div>
                <Row className=" inner_row mx-0 mb-2">
                  <Col md={10} xs={7} className="col_whole_card">
                    <div className="w-5/6 m-auto">
                      <div className="mt-10 pb-3">
                        <Slider
                          {...filterslider}
                          className="overflow-hidden filter_slider"
                        >
                          {(this.state.filterdata || []).map((filter, key) => (
                            <div
                              className="list-unstyled profession_ul"
                              key={key}
                            >
                              <div
                                className={`text-center profession_li mt-1 ${this.state.activeFilterLink ===
                                  filter.filterName
                                  ? 'active'
                                  : ''
                                  }`}
                                onClick={() => {
                                  this.setState({ currentPage: 1 }),
                                    this.professionLink(
                                      filter.filterName,
                                      filter.value,
                                      filter.type,
                                    );
                                }}
                              >
                                <img
                                  src={filter.image}
                                  className="professionIcon"
                                  alt={filter.filterName}
                                />
                              </div>
                              <p
                                className={`filter_title fw-normal text-center m-auto  ${this.state.activeFilterLink === filter.filterName ? 'active_text' : ''}`}
                                style={{ padding: `5px 9px 5px 9px` }}
                                onClick={() => {
                                  this.setState({ currentPage: 1 }),
                                    this.professionLink(
                                      filter.filterName,
                                      filter.value,
                                      filter.type,
                                    );
                                }}
                              >
                                {filter.filterName}
                              </p>
                            </div>
                          ))}
                        </Slider>
                      </div>
                    </div>
                  </Col>
                  <Col md={2} xs={4} className="pt-2">
                    <div
                      style={{
                        padding: `5px 0px 5px 0px`,
                        height: `70px`,
                        flexDirection: 'column',
                        alignItems: 'center',
                      }}
                      className={`text-center profession_custum_li mt-2 profession_li mt-1 d-flex justify-content-evenly ${this.state.activeFilterLink == 'other' ? 'active' : ''}`}
                      onClick={() => this.openfilterModal()}
                    >
                      <img
                        src={filterIcon}
                        style={{
                          height: `19px`,
                          width: `17px`,
                          margin: `22px`,
                        }}
                      />
                    </div>
                    <p className="custum-filter fw-normal text-center pt-1">
                      Custom Filter
                    </p>
                  </Col>
                </Row>
                {!this.state.showMapPage && (

                  <Row className="mt-3 mb-1 mx-auto">
                    <Col md={8} xs={12} className="">

                      <form
                        className="srch_frm_sction"
                        onSubmit={(e) => {
                          e.preventDefault();
                          this.handleSearchItem();
                        }}
                      >
                        <Input
                          type="text"
                          placeholder="Search by name, profession, location"
                          value={this.state.searchItem}
                          className="search_input  px-3 d-inline me-3"
                          onChange={(e) =>
                            this.setState({ searchItem: e.target.value })
                          }
                        />

                        <button
                          className={`btn btn-outline-primary`}
                          disabled={!this.state.searchItem}
                          onClick={() => this.handleSearchItem()}
                        >
                          Search
                        </button>
                        <div
                          className="btn"
                          onClick={() => {
                            this.getAllMemberDetails(1, ListProfilesSquareClass.MemberType.ALLIANCE_SEEKER);
                            this.setState({
                              currentPage: 1,
                              currentPageNo: 1,
                              currentItems: null,
                              filterData: null,
                              activeFilterLink: '',
                              searchItem: '',
                            });
                          }}
                        >
                          <i
                            className={`fa fa-refresh position-static fa-lg refresh-button btn btn-outline-primary`}
                            aria-hidden="true"
                          ></i>{' '}
                          {/* Refresh */}
                        </div>
                      </form>
                    </Col>

                  </Row>

                )}

                {this.state.showMapPage == true && (
                  <MapFunction membertype={ListProfilesSquareClass.MemberType.ALLIANCE_SEEKER} />
                )}

                {isLoading && this.state.noDataFound == false ? (
                  <Row className="text-center">
                    {[1, 2, 3, 4].map((key) => (
                      <Col
                        key={key}
                        className="mt-4 pt-2 text-center col-xl-3 col-lg-4 col-md-6 col-sm-6 col-auto"
                      >
                        <Card className="catagories overflow-hidden rounded border-0">
                          <ContentLoader
                            speed={2}
                            width={280}
                            height={400}
                            viewBox="0 0 280 400"
                            backgroundColor="#f3f3f3"
                            foregroundColor="#ecebeb"
                            {...this.props}
                          >
                            {/* Image skeleton */}
                            <rect
                              x="0"
                              y="0"
                              rx="5"
                              ry="5"
                              width="280"
                              height="200"
                            />

                            {/* Other content placeholders */}
                            <rect
                              x="0"
                              y="220"
                              rx="5"
                              ry="5"
                              width="280"
                              height="20"
                            />
                            <rect
                              x="0"
                              y="250"
                              rx="5"
                              ry="5"
                              width="280"
                              height="20"
                            />
                            <rect
                              x="0"
                              y="280"
                              rx="5"
                              ry="5"
                              width="140"
                              height="20"
                            />
                            <rect
                              x="150"
                              y="280"
                              rx="5"
                              ry="5"
                              width="130"
                              height="20"
                            />
                            <circle cx="30" cy="330" r="15" />
                            <circle cx="70" cy="330" r="15" />
                            <circle cx="110" cy="330" r="15" />
                          </ContentLoader>
                        </Card>
                      </Col>
                    ))}
                  </Row>
                ) : (
                  <span>
                    {this.state.showMapPage == false && (
                      <>
                        {/* advertisement cardd */}
                        {/* {((key == 4) || (key == 8) || (key == 9)) && (this.state.advertisementdata || []).
                          slice((key == 4 ? 0 : (key == 8 ? 1 : 2)), key == 4 ? 1 : (key == 8 ? 2 : 3)).map((item, adkey) => (
                            <Col lg={4} md={6} xs={12} className="mt-4 pt-2 px-4 text-center" key={adkey}>
                              <Card className="catagories rounded container border border-0 h-300">
                                <CardBody className='rounded mt-1 shadow card p-0'>

                                  <Slider className='imageSlider ' {...settings}>
                                    <img src={item.imagepath[0]} alt={'Advertisement'} className='img_thumb' />
                                    <img src={item.imagepath[1]} alt={'Advertisement'} className='img_thumb' />
                                    <img src={item.imagepath[2]} alt={'Advertisement'} className='img_thumb' />
                                  </Slider>
                                </CardBody>
                              </Card>
                              <Card className="catagories rounded container border border-0 h-300">

                                <CardBody className='rounded mt-1 shadow card p-0 h-160'>

                                  <a href={item.url} target="_blank" className="btn btn-primary advertise-button mx-2" style={{ backgroundColor: `${item.btncolor}`, textTransform: 'uppercase' }} rel="noopener noreferrer">
                                    {item.buttontext}
                                  </a>
                                  <p className="text-dark h5 name">
                                    <span style={{ color: `#0D6EFD` }}>{item.title}, </span>
                                  </p>
                                  {item.description ? (
                                    <p className=" my-1 font_size16 text-accent">{item.description}</p>) :
                                    (<p className=" my-1 font_size16 text-accent">Unknown Profession</p>)}

                                </CardBody>
                              </Card>
                            </Col>
                          ))} */}

                        <Row>
                          {(currentItems
                            ? currentItems
                            : this.state.memberList || []
                          ).map((item, key) => (
                            <Col
                              key={key}
                              className="pt-2 text-center col-xl-3 col-lg-4 col-md-6 col-sm-6 col-xs-12"
                            >
                              {/* <Col lg={3} md={4} sm={6} xs={12} className="mt-4 pt-2 text-center" key={key}> */}
                              <Card className="catagories  rounded rounded-3 overflow-hidden border-1 border-primary shadow">
                                <CardBody className="rounded overflow-hidden p-0 ">
                                  <ul
                                    className="list-unstyled align-items-center width100 position-absolute  "
                                    style={{ zIndex: `9`, marginBottom: `0px` }}
                                  >
                                    <li className="list-inline-item float-start m-2">
                                      <span className="badge rounded-pill text-dark border  bg-light border-1 border-dark font_size14">
                                        {(item.maritalstatus == '' ||
                                          item.maritalstatus == null) && (
                                            <span className="font_size14">
                                              {' '}
                                              Unknown Status
                                            </span>
                                          )}
                                        {item.maritalstatus != '' && (
                                          <span className="font_size14">
                                            {' '}
                                            {item.maritalstatus}
                                          </span>
                                        )}
                                      </span>
                                    </li>
                                    <li className="list-inline-item float-end m-2">
                                      <Button
                                        className="like btn-transparent p-0"
                                        style={{
                                          background: `none`,
                                          border: `none`,
                                        }}
                                        onClick={() =>
                                          this.openConfirmModal(
                                            item.memberuuid,
                                            item.likedstatus,
                                          )
                                        }
                                      >
                                        <i
                                          className={`mdi mdi-heart h5 mb-0 ${item.likedstatus === 1 ? 'redheart' : ''}`}
                                        ></i>
                                      </Button>
                                    </li>
                                  </ul>

                                  <Slider
                                    className="imageSlider image-container"
                                    {...settings}
                                  >
                                    <img
                                      src={this.displayImage(
                                        item.photothumb1,
                                        item.gender,
                                      )}
                                      className="img-fluid img_thumb"
                                    />
                                    <img
                                      src={this.displayImage(
                                        item.photothumb2,
                                        item.gender,
                                      )}
                                      className="img-fluid img_thumb"
                                    />
                                    <img
                                      src={this.displayImage(
                                        item.photothumb3,
                                        item.gender,
                                      )}
                                      className="img-fluid img_thumb"
                                    />
                                  </Slider>
                                </CardBody>
                              </Card>
                              <Card className="catagories rounded rounded-3 mt-1 overflow-hidden shadow">
                                <CardBody className="rounded shadow p-2 text_card_body">
                                  <p className="text-dark h5 name mb-0">
                                    <span style={{ color: `#0D6EFD` }}>
                                      {item.firstname},{' '}
                                    </span>{' '}
                                    <span className="text-primary">
                                      {this.calculate_age(item.dob)}
                                    </span>
                                    {item.height != 0 &&
                                      item.height != null && (
                                        <span className="h6">
                                          , {item.height}
                                        </span>
                                      )}
                                  </p>
                                  <p className="m-0 font_size14">
                                    <FeatherIcon
                                      icon="briefcase"
                                      className="fea icon-sm me-1"
                                    />
                                    {item.jobprofession
                                      ? item.jobprofession
                                      : 'Unknown Profession'}
                                  </p>
                                  <p className="m-0 font_size14">
                                    <FeatherIcon
                                      icon="map-pin"
                                      className="fea icon-sm me-1"
                                    />
                                    {item.currentlocationname}
                                  </p>
                                  <div className=" d-flex justify-content-center">
                                    <div className="m-1">
                                      <Link
                                        to="/viewprofiledetails"
                                        state={{
                                          data: item.memberuuid,
                                          connectionStatus:
                                            item.connectionstatus,
                                          userage: this.calculate_age(item.dob),
                                        }}
                                        className="btn btn-outline-primary"
                                      >
                                        <span className="d-flex">
                                          <i>
                                            <FeatherIcon
                                              icon="eye"
                                              className="fea icon-sm icons px-1"
                                              style={{ width: `30px` }}
                                            />
                                          </i>{' '}
                                          <span className="d-md-block">
                                            Connect & Message{' '}
                                          </span>
                                        </span>
                                      </Link>
                                    </div>
                                  </div>
                                  <div className="row d-none">
                                    <div className="col-md-12">
                                      <span
                                        style={{
                                          border: `1px solid #0d6efd`,
                                          padding: `4px 10px 5px 10px`,
                                          borderRadius: ` 24px`,
                                        }}
                                      >
                                        <label className="title text-dark font_weight300 font_size14 mt-2">
                                          Referred By:
                                        </label>
                                        <button
                                          onClick={() =>
                                            this.openModal(
                                              item.recommendersuuid,
                                            )
                                          }
                                          className="btn btn-transparent p-0 text-primary cursor-pointer font-size15 font_weight300"
                                        >
                                          {item.recommendertype === ''
                                            ? 'Unknown Referral'
                                            : item.recommendertype}
                                        </button>
                                      </span>
                                    </div>
                                  </div>
                                </CardBody>
                              </Card>
                            </Col>
                          ))}

                          <Col xs={12} className="my-3 pt-2">
                            {totalPages > 1 && (
                              <ul className="pagination justify-content-center mb-0">
                                <PaginationItem disabled={currentPageNo === 1}>
                                  <PaginationLink
                                    to="#"
                                    aria-label="Previous"
                                    onClick={() =>
                                      this.handlePageClick(currentPageNo - 1)
                                    }
                                  >
                                    {'<<'}
                                  </PaginationLink>
                                </PaginationItem>
                                {pageNumbers.map((number) => (
                                  <PaginationItem
                                    active={number === currentPageNo}
                                    key={number}
                                    disabled={
                                      number === '...' || number === '....'
                                    }
                                  >
                                    <PaginationLink
                                      onClick={() =>
                                        this.handlePageClick(number)
                                      }
                                    >
                                      {number}
                                    </PaginationLink>
                                  </PaginationItem>
                                ))}
                                <PaginationItem
                                  disabled={currentPageNo === totalPages}
                                >
                                  <PaginationLink
                                    to="#"
                                    aria-label="Next"
                                    onClick={() =>
                                      this.handlePageClick(currentPageNo + 1)
                                    }
                                  >
                                    {'>>'}
                                  </PaginationLink>
                                </PaginationItem>
                              </ul>
                            )}
                          </Col>
                        </Row>
                      </>
                    )}
                    {this.state.totalCount == 0 && !isLoading && (
                      <div className="text-center mt-2 mb-4 label_text fs-3">
                        No Member Found
                      </div>
                    )}
                  </span>
                )}
              </div>
            </section>
          </div>
          <BackToTop />

          <div
            id="chatbox"
            style={{
              display: 'none',
              position: 'fixed',
              bottom: '20px',
              right: '20px',
              zIndex: 99999,
              width: '316px',
            }}
          >
            <div className="card">
              <div className="card-header">
                <h6 className="text-white my-2 mx-2">
                  {' '}
                  Messages
                  <button
                    className="btn btn-sm btn-link float-end p-0"
                    onClick={() => this.closeShortChat()}
                  >
                    <i className="fa fa-times fa-2x position-static"></i>
                  </button>
                </h6>
              </div>
              <div className="card-body p-0">
                <ChatBoxFunction memberdata={this.state.chatMemberData} />
              </div>
            </div>
          </div>
        </div>
        <Modal isOpen={this.state.modalOpen} toggle={this.closeModal} size="xl">
          <ModalHeader toggle={this.closeModal}>
            Profile Details
            <button
              type="button"
              className="btn shareBtn"
              onClick={() => this.openCopyModal(this.state.membersFullData)}
            >
              <span className="d-flex">
                <span className="d-none d-md-block">Share This Profile </span>
                <i>
                  <FeatherIcon
                    icon="share-2"
                    className="fea icon-sm icons px-1"
                    style={{ width: `30px` }}
                  />
                </i>
              </span>
            </button>
            <button
              type="button"
              className="close"
              aria-label="Close"
              onClick={this.closeModal}
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </ModalHeader>

          {this.state.isModalLoading ? (
            <ModalBody>
              <ModalBodySkeleton />
            </ModalBody>
          ) : (
            <ProfileModal
              memberData={this.state.membersFullData}
              location={[
                this.state.nativeLocation,
                this.state.currentLocation,
                this.state.companyLocation,
              ]}
              dob={this.state.dob}
            />
          )}
        </Modal>

        <Modal
          isOpen={this.state.filtermodalOpen}
          toggle={this.closeFilterModal}
          size="lg"
        >
          <ModalHeader toggle={this.closeFilterModal}>
            Custom Filters
            <button
              type="button"
              className="close"
              aria-label="Close"
              onClick={this.closeFilterModal}
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </ModalHeader>
          <ModalBody>
            <Row>
              <Col lg="12">
                <Card
                  className=" border-0 rounded shadow"
                  style={{ zIndex: '9' }}
                >
                  <CardBody className="card-body">
                    <Row className="">
                      <>
                        <div className="features feature-primary p-1">
                          <div className="mb-3">
                            <Label className="form-label label_text">
                              Age Range
                            </Label>
                            <Sliderr
                              range
                              defaultValue={[0, 100]}
                              onChange={this.handleRangeChange}
                              tipFormatter={this.tipFormatter}
                            />
                            <Row>
                              <Col md={2} className="d-flex mt-2">
                                <p className="form-control">
                                  {' '}
                                  {this.state.rangeValues[0]}{' '}
                                </p>
                                <span className="mx-4 mt-2"> - </span>
                                <p className="form-control">
                                  {' '}
                                  {this.state.rangeValues[1]}{' '}
                                </p>
                              </Col>
                            </Row>
                          </div>
                        </div>
                        <hr />

                        <div className="features feature-primary p-1">
                          <div className="mb-3">
                            <Label className="form-label label_text">
                              Degree Level
                            </Label>
                            <div className="form-icon position-relative">
                              <ul className="ks-cboxtags p-0">
                                {this.state.degreeLevelList.map(
                                  (response, index) => (
                                    <div
                                      className="form-check form-check-inline form_check_box"
                                      key={index}
                                    >
                                      <li>
                                        <div className="mb-0">
                                          <div className="form-check p-0">
                                            <Input
                                              type="checkbox"
                                              checked={this.state.otherFilterRequestBody.degreeLevelList.includes(
                                                response.value,
                                              )}
                                              id={
                                                response.label + 'degreelevel'
                                              }
                                              onChange={(event) =>
                                                this.handleDegreeLevel(
                                                  event.target.checked,
                                                  response.value,
                                                )
                                              }
                                            />
                                            <Label
                                              className="form-check-label"
                                              htmlFor={
                                                response.label + 'degreelevel'
                                              }
                                            >
                                              {response.label}{' '}
                                            </Label>
                                          </div>
                                        </div>
                                      </li>
                                    </div>
                                  ),
                                )}
                              </ul>
                            </div>
                          </div>
                        </div>

                        <hr />

                        <div className="features feature-primary p-1">
                          <div className="mb-3">
                            <Label className="form-label label_text">
                              Preferred Degree Names
                            </Label>
                            <div className="form-icon position-relative">
                              <ul className="ks-cboxtags p-0">
                                {visibleDegreeNameList.map(
                                  (response, index) => (
                                    <div
                                      className="form-check form-check-inline form_check_box"
                                      key={index}
                                    >
                                      <li>
                                        <div className="mb-0">
                                          <div className="form-check p-0">
                                            <input
                                              type="checkbox"
                                              id={response.label + 'degreename'}
                                              checked={this.state.otherFilterRequestBody.degreeNameList.includes(
                                                response.value,
                                              )}
                                              onChange={(event) =>
                                                this.handleDegreeNames(
                                                  event.target.checked,
                                                  response.value,
                                                )
                                              }
                                            />
                                            <label
                                              className="form-check-label"
                                              htmlFor={
                                                response.label + 'degreename'
                                              }
                                            >
                                              {response.label}
                                            </label>
                                          </div>
                                        </div>
                                      </li>
                                    </div>
                                  ),
                                )}
                              </ul>
                            </div>
                            {this.state.degreeNameList.length > 3 && (
                              <button
                                className="btn show_more_btn"
                                onClick={this.toggleShowAllDegreeNames}
                              >
                                {showAllDegreeNames ? 'Show Less' : 'Show More'}
                              </button>
                            )}
                          </div>
                        </div>
                        <hr />
                        <div>
                          <Label className="form-label label_text">
                            Profession{' '}
                          </Label>
                          <div className="features feature-primary p-1">
                            <div className="mb-3">
                              <div className="form-icon position-relative">
                                <ul className="ks-cboxtags p-0">
                                  {visibleOccupationList.map(
                                    (response, index) => (
                                      <div
                                        className="form-check form-check-inline form_check_box"
                                        key={index}
                                      >
                                        <li>
                                          <div className="mb-0">
                                            <div className="form-check p-0">
                                              <input
                                                type="checkbox"
                                                id={
                                                  response.label + 'occupation'
                                                }
                                                onChange={(event) =>
                                                  this.handleOccupation(
                                                    event.target.checked,
                                                    response.value,
                                                  )
                                                }
                                              />
                                              <label
                                                className="form-check-label"
                                                htmlFor={
                                                  response.label + 'occupation'
                                                }
                                              >
                                                {response.label}
                                              </label>
                                            </div>
                                          </div>
                                        </li>
                                      </div>
                                    ),
                                  )}
                                </ul>
                              </div>
                              {this.state.occupationList.length > 3 && (
                                <button
                                  className="btn show_more_btn"
                                  onClick={this.toggleShowAllOccupation}
                                >
                                  {showAllOccupation
                                    ? 'Show Less'
                                    : 'Show More'}
                                </button>
                              )}
                            </div>
                          </div>
                        </div>
                        <hr />
                      </>
                      <div>
                        <Row>
                          <Label className="form-label label_text">
                            Location
                          </Label>
                          <Col md={6}>
                            <div className="features feature-primary p-1 filter">
                              <div className="mb-3">
                                <PlacesAutocomplete
                                  value={currentaddress}
                                  onChange={this.handleCurrentAddressChange}
                                  onSelect={this.handleCurrentAddressSelect}
                                  className="form-control form_input"
                                >
                                  {({
                                    getInputProps,
                                    suggestions,
                                    getSuggestionItemProps,
                                    loading,
                                  }) => {
                                    const uniqueSuggestions = new Set();

                                    return (
                                      <div>
                                        <input
                                          {...getInputProps({
                                            placeholder: 'Search Places ...',
                                            className: 'location-search-input',
                                          })}
                                        />
                                        <div className="autocomplete-dropdown-container">
                                          {loading && <div>Loading...</div>}
                                          {suggestions.map(
                                            (suggestion, index) => {
                                              const className =
                                                suggestion.active
                                                  ? 'suggestion-item--active'
                                                  : 'suggestion-item';

                                              const words =
                                                suggestion.description.split(
                                                  ',',
                                                );
                                              const trimmedArray = words.map(
                                                (str) => str.trim(),
                                              );
                                              const lastThreeWords =
                                                trimmedArray
                                                  .slice(-3)
                                                  .join(',');
                                              // Check if the suggestion is unique based on place_id
                                              if (
                                                !uniqueSuggestions.has(
                                                  lastThreeWords,
                                                )
                                              ) {
                                                uniqueSuggestions.add(
                                                  lastThreeWords,
                                                );

                                                // inline style for demonstration purpose
                                                const style = suggestion.active
                                                  ? {
                                                    backgroundColor:
                                                      '#fafafa',
                                                    cursor: 'pointer',
                                                  }
                                                  : {
                                                    backgroundColor:
                                                      '#ffffff',
                                                    cursor: 'pointer',
                                                  };

                                                return (
                                                  <div
                                                    key={index}
                                                    {...getSuggestionItemProps(
                                                      suggestion,
                                                      {
                                                        className,
                                                        style,
                                                      },
                                                    )}
                                                  >
                                                    <span>
                                                      {lastThreeWords}
                                                    </span>
                                                  </div>
                                                );
                                              } else {
                                                return null; // Skip rendering duplicate suggestions
                                              }
                                            },
                                          )}
                                        </div>
                                      </div>
                                    );
                                  }}
                                </PlacesAutocomplete>
                              </div>
                            </div>
                          </Col>
                          <Col md={12}>
                            <div>
                              {selectedPlaces.map((place, index) => (
                                <div key={index} className="selected-place">
                                  <span>{place.address}</span>{' '}
                                  {/* Render address property */}
                                  {/* Optionally, you can render placeid as well */}
                                  <Link
                                    onClick={() =>
                                      this.handleRemovePlace(index)
                                    }
                                    className="rounded p-2"
                                  >
                                    <FeatherIcon
                                      icon="delete"
                                      className="fea icon-sm fea-social text-danger"
                                    />
                                  </Link>
                                </div>
                              ))}
                            </div>
                          </Col>
                        </Row>
                      </div>
                      <hr />

                      <div>
                        <Label className="form-label label_text">Gender </Label>
                        <div className="form-icon position-relative">
                          <ul className="ks-cboxtags p-0">
                            {this.state.genderList.map((response, index) => (
                              <div
                                className="form-check form-check-inline form_check_box"
                                key={index}
                              >
                                <li>
                                  <div className="mb-0">
                                    <div className="form-check p-0">
                                      <Input
                                        type="checkbox"
                                        checked={this.state.otherFilterRequestBody.genderList.includes(
                                          response.value,
                                        )}
                                        id={response.label + 'gender'}
                                        onChange={(event) =>
                                          this.handleGender(
                                            event.target.checked,
                                            response.value,
                                          )
                                        }
                                      />
                                      <Label
                                        className="form-check-label"
                                        htmlFor={response.label + 'gender'}
                                      >
                                        {response.label}{' '}
                                      </Label>
                                    </div>
                                  </div>
                                </li>
                              </div>
                            ))}
                          </ul>
                        </div>
                      </div>
                      <hr />
                      <div>
                        <Label className="form-label label_text">
                          Religion{' '}
                        </Label>
                        <div className="features feature-primary p-1">
                          <div className="mb-3">
                            <div className="form-icon position-relative">
                              <ul className="ks-cboxtags p-0">
                                {visiblereligionList.map((response, index) => (
                                  <div
                                    className="form-check form-check-inline form_check_box"
                                    key={index}
                                  >
                                    <li>
                                      <div className="mb-0">
                                        <div className="form-check p-0">
                                          <input
                                            type="checkbox"
                                            id={response.label + 'religion'}
                                            onChange={(event) =>
                                              this.handleReligion(
                                                event.target.checked,
                                                response.value,
                                              )
                                            }
                                          />
                                          <label
                                            className="form-check-label"
                                            htmlFor={
                                              response.label + 'religion'
                                            }
                                          >
                                            {response.label}
                                          </label>
                                        </div>
                                      </div>
                                    </li>
                                  </div>
                                ))}
                              </ul>
                            </div>
                            {this.state.religionList.length > 3 && (
                              <button
                                className="btn show_more_btn"
                                onClick={this.toggleShowAllReligion}
                              >
                                {showAllreligion ? 'Show Less' : 'Show More'}
                              </button>
                            )}
                          </div>
                        </div>
                      </div>
                      <hr />
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <button className="btn btn-primary" onClick={this.otherFilterApply}>
              Show Results
            </button>
          </ModalFooter>
        </Modal>

        <Modal
          isOpen={this.state.confirmConnectionmodalOpen}
          toggle={this.closeConnectionModal}
        >
          <ModalHeader toggle={this.closeConnectionModal}>
            Confirmation
            <button
              type="button"
              className="close"
              aria-label="Close"
              onClick={this.closeConnectionModal}
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </ModalHeader>
          <ModalBody>
            <Row>
              <Col lg="12">
                <p className="mb-0">Are You Sure to Connect?</p>
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter className="p-1">
            <Button
              color="secondary"
              onClick={this.closeConnectionModal}
              type="button"
            >
              Close
            </Button>
            <Button
              color="success"
              type="button"
              onClick={this.requestForConnection}
            >
              Connect
            </Button>
          </ModalFooter>
        </Modal>
        <Modal
          isOpen={this.state.confirmmodalOpen}
          toggle={this.closelikeModal}
        >
          <ModalHeader toggle={this.closelikeModal}>
            Confirmation
            <button
              type="button"
              className="close"
              aria-label="Close"
              onClick={this.closelikeModal}
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </ModalHeader>
          <ModalBody>
            <Row>
              <Col lg="12">
                <p className="mb-0">
                  Are You Sure to {this.state.likedStatus} ?
                </p>
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter className="p-1">
            <Button
              color="secondary"
              onClick={this.closelikeModal}
              type="button"
            >
              Close
            </Button>
            <Button color="danger" type="button" onClick={this.favoriteMember}>
              {this.state.likedStatus}
            </Button>
          </ModalFooter>
        </Modal>

        <Modal
          isOpen={this.state.copyModalOpen == true}
          toggle={this.toggleCopyModal}
        >
          <ModalHeader toggle={this.toggleCopyModal}>
            Share Url
            <button
              type="button"
              className="btn shareUrlbtn btn-dark"
              onClick={this.handleCopyText}
            >
              <span aria-hidden="true">
                {this.state.copyButton == 'Copy Url' ? (
                  <i>
                    <FeatherIcon icon="copy" className="fea icon-sm icons" />
                  </i>
                ) : (
                  <i>
                    <FeatherIcon icon="check" className="fea icon-sm icons" />
                  </i>
                )}{' '}
                {this.state.copyButton}
              </span>
            </button>
            <button
              type="button"
              className="close"
              aria-label="Close"
              onClick={this.toggleCopyModal}
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </ModalHeader>
          <ModalBody className="copy_Modal_body">
            <Row>
              <Col lg="12">
                <p className="mb-0">
                  {this.state.pageUrl}
                  {'index/'}
                  {this.state.sharedMemberUuid}
                </p>
              </Col>
            </Row>
          </ModalBody>
        </Modal>

        {/* <Modal isOpen={this.state.mutualonnectionmodalOpen} toggle={this.closemutualModal}>
          <ModalHeader toggle={this.closemutualModal}>Mutual Connections
            <button type="button" className="close" aria-label="Close" onClick={this.closemutualModal}>
              <span aria-hidden="true">&times;</span>
            </button>
          </ModalHeader>
          <ModalBody>
            <Row>
              <Col lg="12">
                {(this.state.mutualConnectionsData || []).map((item, key) => (
                  <Col xs={12} className="pt-2" key={key}>
                    <div className="job-box bg-white d-md-flex align-items-center border-0 shadow rounded p-4 position-relative ">

                      <div className="ms-md-4 mt-4 mt-sm-0">
                        <p className="text-primary h5" onClick={() => this.openModal(item.memberuuid)}>{item.firstname} {' '} {item.lastname}
                        </p>
                      </div>
                    </div>
                  </Col>
                ))}
              </Col>
            </Row>
          </ModalBody>
        </Modal> */}
      </React.Fragment>
    );
  }
}

export default ListProfilesSquareClass;
